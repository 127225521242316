// Global component styles

body {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: $white;
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100% 550px;
    -webkit-flex-direction: column;
    flex-direction: column;
    color: #3f4254;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    //background-blend-mode: multiply;
    //letter-spacing: 0.01rem;
    
    @media (max-width: 500px){
        background-size: 50em;
    }
}



#root {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
}

main {
    min-height: 80vh;
}

.presentation-title{
    font-family: $fonte-primaria-negrito;
    font-size: 8em !important;
    font-weight: 700;
    margin: 0;
    color: #FFFFFF;
    // background: #fbf8ec;
    // background: -moz-linear-gradient(to bottom, #FFFFFF 35%, #4e6773 100%);
    // background: -webkit-linear-gradient(to bottom, #FFFFFF 35%,#4e6773 100%);
    // background: -ms-linear-gradient(to bottom, #FFFFFF 35%,#4e6773 100%);
    // background: -o-linear-gradient(to bottom, #FFFFFF 35%,#4e6773 100%);
    // background: linear-gradient(to bottom, #FFFFFF 35%,#4e6773 100%);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
}

.presentation-subtitle{
    font-size: 1.5em;
    color: #FFFFFF;
}


@media (max-width: 500px){
    .presentation-title{
        font-size: 3em !important;
    }
    .presentation-subtitle{
        font-size: 1em !important;
    }
}


#cabecalho-pagina{
   
    @media (max-width: 500px){
        h1{
            font-size: 2.2em;
        }
        h3, h4{
            font-size: 1.5em;
        }
    }
}

#conteudo-pagina-inicial{
    margin-top: 10rem;
    z-index: 10000 ;
 
     ul, ol{
         list-style: none;
         margin: 0;
         padding: 0;
     }
 
     .jumbotron{
        //background-color: $gray-200;
        //background-color: #e9ecef;
        //background-color: #deefdc;
        //background-color: #e2dad1;
         padding: 2rem;
         //border-radius: 0.35rem;
         font-family: $fonte-primaria-light;
     }
     
 
     @media (max-width: 500px){
         margin-top: 1.5rem;
 
         .jumbotron{
             h1, .h1{
                 font-size: 2.5em !important;
             }
         }
     }
 }


#conteudo-pagina-interna{
    margin-top: 15rem;
    z-index: 10000 ;
 
     ul, ol{
         list-style: none;
         margin: 0;
         padding: 0;

         li{
            font-size: $font-paragraph;
        }
     }

     
 
     @media (max-width: 500px){
         margin-top: 8rem;
 
     }
 }
 

.bg-half #conteudo-pagina-interna{
    margin-top: 3rem;
}

#conteudo-pagina-w100{
    width:100%;
    margin-top:5rem;
    padding:5rem;

    @media (max-width: 500px){
        margin-top: 1.5rem;
        padding:1.5rem;
    }
}



#profile-menu {
    padding: 0;
    margin: 0;

    li {
        display: inline-block;
        text-align: center;
        margin: 5px; 
        padding:0;

        a {
            text-transform: uppercase;
            font-weight: bold;
            &:hover {
                background-color: #0080c7;
            }
        }
    }

    
    li::firt-child() {
        margin-left: 0px;
    }

    li::last-child() {
        margin-right: 0px;
    }
}


@media screen and (max-width: 991px) {
#profile-menu {
    li {
        display: block !important;
        margin: 20px 0 !important;
        a{
            //padding: 0px !important;
            width: 100%;
        }
    }
}
}


//forms 
.form-control{
    font-weight: 300;
}

.input-outline{
    background:none;
    border: 2px solid white;
    color: white;
    &:focus, &:active{
        outline: 0;
        background: none;
        color: white;
        border: 2px solid white;
    }
}

.input-group-text {
    padding: 0.61rem 0.75rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background: white;
}

.input-group-prepend + .form-control{
    border-left: none;
    padding-left: 0;
  }

