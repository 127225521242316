// Estilos de Cards

.card {
    // background-color: #fff;
    // background-clip: border-box;
    // border-radius: 0.35rem;
    // word-wrap: break-word;
    // box-shadow: 0 0 30px 0 rgb(82 63 105 / 5%);
    // border: 0;

    border-radius: 0.35rem;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
    background-color: #FFFFFF;
    color: #000;
    margin-bottom: 20px;
    position: relative;
    z-index: 1;
    border: 0 none;
    -webkit-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
    -moz-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
    -o-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
    -ms-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
    transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;

    &:not(.card-plain):hover {
        box-shadow: 0px 12px 19px -7px rgba(0, 0, 0, 0.3);
        transform: translateY(-10px);
        -webkit-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
        -moz-transform: translateY(-10px);
    }

    .card-header {
        
        display: flex;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        background: none;

        h1, .h1{
          padding:0;
          margin: 0;
        }
    }

    .card-title {
        font-weight: bold;
        font-size: 1.5rem;
        color: $black;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        -webkit-align-items: center;
    }

    .card-body {
        
        .card-subtitle {
            color: $gray-600;
        }

    }
    .card-grupo{
        font-size:0.8rem ;
    }
    


    //@medias

    @media (max-width: 991.98px) {
        .card-header {
            //padding: 1rem;
        }

        .card-body {
            //padding: 1rem;

        }
    }
}

.card-login {
    background-color: #d5e2e9;
    border-radius: 8px;
    max-width: 350px;
    min-height: 400px;
    z-index: 1;
    padding:2rem;

    label, .label {
        color: #000;
        margin-top: 15px;
        font-weight: 300;
    }

    .card-header {
        color: #000;
        text-align: center;
        font-weight: 300;
        width: 100%;

    }

    .input-group-text, input[type=text], input[type=password]{
      border:0;
    }

}

.card-info {
    display: flex; 
    justify-content: space-between; 
  }
  
.card-info-item {
flex: 1; 
margin-right: 10px; 
margin-bottom: 10px; 
font-weight: 700;
}


.card-border{
    border-left: 3px solid rgba(252, 170, 18, 0.897);
}
