// Override do Bootstrap variáveis padrão aqui
// Não editar qualquer arquivo em /vendor/bootstrap/scss/!

// Variáveis de cor
// Sobrescritas de cores do Bootstrap

$white: #fff !default;
$gray-100: #eef0f8 !default;
$gray-200: #eaecf4 !default;
$gray-300: #dddfeb !default;
$gray-400: #d1d3e2 !default;
$gray-500: #b7b9cc !default;
$gray-600: #858796 !default;
$gray-700: #6e707e !default;
$gray-800: #5a5c69 !default;
$gray-900: #3a3b45 !default;

$gray: gray !default;
$light-gray:                 #E3E3E3 !default;
$medium-gray:                #DDDDDD !default;
$dark-gray:                  #9A9A9A !default;
$more-dark-gray:                  #838383 !default;
$black: #000 !default;

// Cores customizadas (pelas cores dos portais e do MIV mais recente / 2021)

$blue: #005baa !default;
$lightblue: #7e9aeb !default;
$morelightblue: #b9cafa !default;
$yellow: #fcaa20 !default;
$green: #61a057 !default;
$orange: #ec7b0b !default;
$red: #e74a3b !default;

// $primary:       $blue !default;
// $secondary:     $orange !default;
$primary:       $orange !default;
$secondary:     $red !default;
$success:       $green !default;
$info:          $morelightblue !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;

// Tipografia
$body-color: $gray-600 !default;

//Font sizes
$font-size-h1:                 3.6em !default;
$font-size-h2:                 2.8em !default;
$font-size-h3:                 1.825em !default;
$font-size-h4:                 1.6em !default;
$font-size-h5:                 1.35em !default;
$font-size-h6:                 0.9em !default;
$font-paragraph:               15px !default;
$font-size-navbar:             14px !default;

//$font-family-sans-serif: "PathwayGothicOne", "Oswald", Impact, sans-serif !default;
$font-family-sans-serif: 'Montserrat R', "Helvetica", Arial, sans-serif;
$fonte-primaria : $font-family-sans-serif;
$fonte-primaria-light : 'Montserrat L', "Helvetica", Arial, sans-serif;
$fonte-primaria-negrito : 'Montserrat B', "Helvetica", Arial, sans-serif;
$fonte-secundaria : "Poppins" , sans-serif !default;

// Sombras
$box-shadow-sm: 0 0.125rem 0.25rem 0 rgba($gray-900, .2) !default;
$box-shadow: 0 0.15rem 1.75rem 0 rgba($gray-900, .15) !default;
// $box-shadow-lg: 0 1rem 3rem rgba($black, .175) !default;

// Arredondamento de bordas
$border-radius: 0.35rem !default;
$border-color: darken($gray-200, 2%) !default;

// Variáveis de espaçamento
// Mudar abaixo se o tamanho navbar mudar
$topbar-base-height: 4.375rem !default;

// Cards
$card-cap-bg: $gray-100 !default;
$card-border-color: $border-color !default;

// Ajuste o espaçamento da coluna para simetria
$spacer: 1rem !default;
$grid-gutter-width: $spacer * 1.5 !default;

// Transições
$transition-collapse: height .15s ease !default;

// Dropdowns
$dropdown-font-size: 0.8rem !default;
$dropdown-border-color: $border-color !default;

// Imagens
$login-image: '' !default;
$register-image: '' !default;
$password-image: '' !default;
