// Text Colors

.text-gray-100 {
  color: $gray-100 !important;
}

.text-gray-200 {
  color: $gray-200 !important;
}

.text-gray-300 {
  color: $gray-300 !important;
}

.text-gray-400 {
  color: $gray-400 !important;
}

.text-gray-500 {
  color: $gray-500 !important;
}

.text-gray-600 {
  color: $gray-600 !important;
}

.text-gray-700 {
  color: $gray-700 !important;
}

.text-gray-800 {
  color: $gray-800 !important;
}

.text-gray-900 {
  color: $gray-900 !important;
}


.opacity-1{
  opacity: 1 !important;
}

.text-light{
  color: $light !important;
}

.text-muted{
  color: $gray-500 !important;
}

.text-black{
  color: $black !important;
}

.text-shadow-black{
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
}

.text-shadow-orange{
  text-shadow: 1px 0 0 $primary, -1px 0 0 $primary, 0 1px 0 $primary, 0 -1px 0 $primary, 1px 1px $primary, -1px -1px 0 $primary, 1px -1px 0 $primary, -1px 1px 0 $primary;
  //text-shadow: 2px 0 0 $primary, -2px 0 0 $primary, 0 2px 0 $primary, 0 -2px 0 $primary, 2px 2px $primary, -2px -2px 0 $primary, 2px -2px 0 $primary, -2px 2px 0 $primary;
  //text-shadow: 3px 0 0 $primary, -3px 0 0 $primary, 0 3px 0 $primary, 0 -3px 0 $primary, 3px 3px $primary, -3px -3px 0 $primary, 3px -3px 0 $primary, -3px 3px 0 $primary;
  //text-shadow: 5px 0 0 $primary, -5px 0 0 $primary, 0 5px 0 $primary, 0 -5px 0 $primary, 5px 5px $primary, -5px -5px 0 $primary, 5px -5px 0 $primary, -5px 5px 0 $primary;
}


//Alignments

.text-justify, .text-justified{
  text-align: justify !important;
}


//Text size and appearence

.uppercase {
  text-transform: uppercase;
}

.uppercase::-webkit-input-placeholder { /* Edge */
  text-transform: none;
}

.uppercase:-ms-input-placeholder { /* Internet Explorer 10-11 */
  text-transform: none;
}

.uppercase::placeholder {
  text-transform: none;
}

.text-small {
  font-size: small;
}


//Others

.icon-circle {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
50% {
  opacity: 0.3;
  }
}

.w-100.text-left{
  text-align: left;
}


//@medias (das customizações)

@media (max-width: 991.98px) {
  .sumir-se-cel{
      display: none;
  }
}

@media (min-width: 992px) {
  .sumir-se-pc{
      display: none;
  }
}
