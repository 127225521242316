// Background Gradient Utilities

@each $color, $value in $theme-colors {
  .bg-gradient-#{$color} {
    background-color: $value;
    background-image: linear-gradient(180deg, $value 10%, darken($value, 15%) 100%);
    background-size: cover;
  }
}

// Grayscale Background Utilities

@each $level, $value in $grays {
  .bg-gray-#{$level} {
    background-color: $value !important;
  }
}


.bg-hover-primary{
  &.hover{
    @include bg-opacity($primary,0.2); 
  }
}

.bg-light:not(.footer){
border-bottom: 8px solid $primary;
}


.bg-cleardark{
  
  $bg-cleardark: $gray-100; //a mesma cor de .bg-light (elemento padrão usado no site)
  $cleardark: $gray-600;

  background-color:$bg-cleardark;
    
  h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
    color: $cleardark;
  }
  
  .input-group-text {
    border: 2px solid $cleardark;
    padding: 0.62rem 0.75rem;
    background: none;
    border-right: none;
    color: $cleardark;
    
  }

  label, .label{
    color:$cleardark;
  }

  .input-outline{
  border: 2px solid $cleardark;
  color:$cleardark;
  }

  input[type=text].input-outline{
    border-left:0;
  }

  

}

.bg-dark{
  background-color:#0b1011 !important;
    
  .input-group-text {
    // background: white;
    border: 2px solid white;
    color:white;
    background: none;
    border-right: none;
  }

  label, .label{
    color:$gray-600;
  }

  .input-outline{
    border: 2px solid white;
  }

}

.background-1{
  background-image: url("../../img/pagina-fundo-1.png");
}

.background-2{
  background-image: url("../../img/pagina-fundo-2.png");
}

.background-3{
  background-image: url("../../img/pagina-fundo-3.png");
}

.background-4{
  background-image: url("../../img/pagina-fundo-4.png");
}

.background-5{
  background-image: url("../../img/pagina-fundo-5.png");
}

.bg-cover{
  background-size: cover;
}

.bg-half{
  background-position: top;
  background-size: contain;
  background-position-y: -33.2rem;
  @media (max-width: 500px){
    background-position-y: 0;
  }
}