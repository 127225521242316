// Sass exclusivo da página de login

.bg-login-image {
  //background: url($login-image);
  background-position: center;
  background-size: cover;
}

.bg-register-image {
  //background: url($register-image);
  background-position: center;
  background-size: cover;
}

.bg-password-image {
  //background: url($password-image);
  background-position: center;
  background-size: cover;
}

form.user {

  .custom-checkbox.small {
    label {
      line-height: 1.5rem;
    }
  }

  .form-control-user {
    font-size: 0.8rem;
    border-radius: 10rem;
    padding: 1.5rem 1rem;
  }

  .btn-user {
    font-size: 0.8rem;
    border-radius: 10rem;
    padding: 0.75rem 1rem;
  }

  .btn-user{
    text-transform: uppercase;
  }

}

