// Custom Dropdown Styling

.dropdown {
  .dropdown-menu {
    font-family: $fonte-primaria-negrito;
    font-size: $dropdown-font-size;
    padding:0rem;
    transition: all .3s ease;
    border: 0 solid rgba(0,0,0,.10);
    border-radius: $border-radius;
    box-shadow: 0 0 50px 0 rgb(82 63 105 / 15%);
    .dropdown-item{
        font-weight: 700;
        transition: all .3s ease;
        cursor: pointer;
        padding: 1rem 1.5rem;
        font-size: 0.8rem;
        text-transform: capitalize;
        img, svg {
          vertical-align: baseline !important;
        }
     
        &.active, &:active{
          //@extend .fw-bolder;
        }

        &.active, &:active, &:hover{
          //@include bg-opacity($primary,0.85);
          background-color: $primary;
          color: $light;
          &:first-child {
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
          }
          &:last-child {
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
          }
          .menu-icone{
            color:$light !important;
          }
        }
    }
  }

  //@medias
  @media (max-width: 991.98px) {
    .dropdown-menu {
      padding:0.4rem 0;
    }
  }
  
}

// Utility class to hide arrow from dropdown
.dropdown.no-arrow {
  .dropdown-toggle::after {
    display: none;
  }
}
