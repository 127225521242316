// navbar
.navbar {
    margin-top: 2rem;

    .navbar-brand {
        text-transform: uppercase;
        font-size: 0.9rem;
        font-weight: bold;
        font-family: $fonte-primaria-negrito;

        .navbar-logo {
            img {
                width: 2.5rem;
            }
        }
    }

    .navbar-toggler {
        border: none;
        padding: 0;
        transition: all .3s ease;

        &:active, &.active, &:focus, &:hover, &:target {
            border: none;
        }

        svg {
            opacity: 10;
        }
    }

    .navbar-toggler-icon {
        padding: 0;
    }

    

    .navbar-nav .show > .nav-link, .navbar-nav .nav-link.active {
        // border: 1px solid hsla(0, 0%, 100%, 0.15);
        // color: $light;
        opacity: 1.0;
    }

    #navbar-menu-nav {
        text-transform: uppercase;

        span {
            font-size: 0.8rem;
            font-weight: bold;
            margin-left: 0.3rem;
        }
    }

    #navbar-menu.collapse {
        transition: all .3s ease;
    }

    .dropdown-menu {
        position: absolute;
        margin: 0.5rem 1.5rem !important;
    }

    .menu-icone {
        color: $gray-400 !important;
    }

    //@medias

    @media (min-width: 992px) {
        margin-top: 1rem;

        .navbar-logo {
            padding: 0;
            -webkit-align-items: center;
            align-items: center;
            margin-right: 25px;
        }

        img {
            max-height: 100px !important;
            display: inline-block;
        }

        #navbar-menu-nav {
            flex-direction: row;
            align-items: center;
        }
    }

    @media (max-width: 991.98px) {
        margin-top: 1rem;

        #navbar-menu.collapse {
            margin-top: .65rem;
        }

        .navbar-logo {
            img {
                max-height: 50px !important;
            }
        }

        .navbar-nav .nav-link {
            padding: .75rem 0;
        }

        .dropdown-menu {
            margin: 0.2rem 0 !important;
        }
    }
}


.navbar-light .navbar-nav, .navbar-dark .navbar-nav {
    .nav-link {
        display: flex;
        font-family: $fonte-primaria-negrito;
        -webkit-align-items: center;
        align-items: center;

        //padding: 0.75rem 1.25rem;
        //margin: 0 0.20rem;
        padding: 0.75rem 0.50rem;
        margin: 0 0.1rem;

        //border-radius: .42rem;
        transition: all .3s ease;
        cursor: pointer;
        font-weight: 400;
        font-weight: bolder;
    }
}

.navbar-light {
    .navbar-nav {
        .nav-link {
            color: $black;
            opacity: 0.4;
        }

        &:hover, &:active, &.active {
            //background-color: hsla(0, 0%, 100%, 0.1);
            //color: $light;
            opacity: 1.0;
        }
    }
}

.navbar-dark {
    .navbar-nav {
        .nav-link {
            color: $white;
            opacity: 0.7;
        }

        &:hover, &:active, &.active {
            //background-color: hsla(0, 0%, 100%, 0.1);
            //color: $light;
            opacity: 1.0 !important;
        }
    }
}