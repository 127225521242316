.footer{
    
    // nav > ul {
    //     list-style: none;
    //     margin: 0;
    //     padding: 0;
    //   }
    //   nav > ul > li{
    //       display: inline-block;
    //       padding: 10px 15px;
    //       margin: 15px 3px;
    //       line-height: 20px;
    //       text-align: center;
    //   }

    //   nav > ul > li:first-child{
    //       margin-left: 0;
    //   }
    //   nav > ul > li:last-child{
    //     margin-right: 0;
    // }
      
    //   nav > ul a:not(.btn) {
    //       color: $gray-800;
    //       display: block;
    //       margin-bottom: 3px;
    //       line-height: 1.6;
    //       opacity: .8;
    //       font-size: 12px;
    //       text-transform: uppercase;
    //       font-weight: 800;
    //       text-decoration: none;
    //       font-family: $fonte-primaria-negrito;
    //   }
    //   nav > ul a:not(.btn):hover,
    //   nav > ul a:not(.btn):focus {
    //     color:$primary;
    //     opacity: 1;
    //   }



    // .copyright {
    //     color:$gray-600;
    //     padding: 10px 0px;
    //     font-size: 14px;
    //     margin: 15px 3px;
    //     line-height: 20px;
    //     text-align: center;
    //     font-weight: 300;
    //   }

    //   @media (max-width: 500px){
    //     margin-top: 0.3rem;
    // }

    img{
      width: 3%;
    }
      
}

body.bg-cover{
  nav > ul a:not(.btn) {
    color:$gray-200;
  }
  nav > ul a:not(.btn):hover,
    nav > ul a:not(.btn):focus {
      color:$white;
      opacity: 1;
    }
}
