header, .header{
    -webkit-justify-content: space-between;
    justify-content: space-between;
    box-shadow: none;
    position: relative;
    z-index: 2;
    //border-bottom: 1px solid hsla(0,0%,100%,.1);
    min-height: 130px;
        
    //@medias

    @media (min-width: 992px) {
        
    }

    @media (max-width: 991.98px) {
        min-height: 80px;
    }


}

.header-fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 97;
    background-color: #fff;
    box-shadow: 0 10px 30px 0 rgb(82 63 105 / 8%);
    height: 80px;
    -webkit-animation: header-minimize-animation .5s ease 1;
    animation: header-minimize-animation .5s ease 1;
}
