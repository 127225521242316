.btn {
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
  font-family: $fonte-primaria-negrito;

  text-transform: uppercase;

  &.fw-bolder {
    @extend .fw-bolder;
  }

  .dropdown-menu {
    margin: 0.5rem 0 !important;
  }
}

//customizações

.btn-primary {
  //padrão GOV RN (2021)
  color: $white !important;
  background: $orange; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    $green 0%,
    $orange 49%,
    $orange 51%,
    $red 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    $green 0%,
    $orange 49%,
    $orange 51%,
    $red 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    $green 0%,
    $orange 49%,
    $orange 51%,
    $red 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$green', endColorstr='$red',GradientType=1 ); /* IE6-9 */
  border: none;

  &:hover {
    background: #e59f1d; /* Old browsers */
    background: -moz-linear-gradient(
      left,
      #598e4e 0%,
      #e59f1d 49%,
      #e59f1d 51%,
      #d83d38 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      left,
      #598e4e 0%,
      #e59f1d 49%,
      #e59f1d 51%,
      #d83d38 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to right,
      #598e4e 0%,
      #e59f1d 49%,
      #e59f1d 51%,
      #d83d38 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#598e4e', endColorstr='#d83d38',GradientType=1 ); /* IE6-9 */
  }

  .dropdown-toggle {
    color: $light !important;
  }
}

.entrar {
  margin-left: 10px !important;
}
.btn-servidor {
  border-radius: 15px; /* Define o raio da borda */
  padding: 7px 30px; /* Define o preenchimento */
  background-color: rgb(230, 230, 230);
  border-color: $primary !important ;
  margin-left: 15px !important;
}

.bnt-termo-fomento {
  background-color: rgba(143, 68, 212, 0.664);
  color: white;
}

.bnt-termo-colaboracao {
  background-color: rgb(55, 175, 55);
  color: white;
}

.bnt-acordo-coperacao {
  background-color: rgb(55, 175, 55);
  color: white;
}
.bnt-status {
  border-radius: 1px; /* Define o raio da borda */
  padding: 7px 15px; /* Define o preenchimento */
  text-align: center;
  color: white;
}

.btn-parceria-em-andamento {
  background-color: rgb(67, 67, 190);
  color: white;
  text-transform: uppercase;
}

.bnt-chamamento-aberto-para-impugnacao {
  background-color: orange;
  color: white;
}

.bnt-ativo {
  background-color: rgb(55, 175, 55);
  color: white;
}

.bnt-encerrado {
  // Estilos para o status inativo
  background-color: rgb(71, 78, 56);
  color: white;
}

.bnt-cancelado {
  background-color: red;
}

.bnt-aguardando {
  background-color: rgb(29, 170, 222);
  color: white;
}

.bnt-pendende {
  // Estilos para o status pendente
  background-color: rgb(172, 172, 57);
}

.bnt-parceria_em_andamento {
  background-color: rgba(0, 74, 185, 0.822);
}

.bnt-chamamento-aberto-para-impugnacao {
  background-color: orange;
}

.bnt-chamamento_fechado_para_impugnacao {
  background-color: orangered;
}

.bnt-encerrado_dipensa_mantida {
  background-color: gray;
}

.bnt-encerrado_dipensa_revogada {
  background-color: rgba(94, 91, 95, 0.877);
}

.btn-light {
  color: $gray-700;
  &:hover {
    background-color: $gray-200;
    border-color: $gray-200;
  }
}

.btn-link {
  text-decoration: none !important;
  color: $primary !important;
  background: none !important;
  border: 1px solid transparent;
  &:hover {
    border: 1px solid $primary;
  }
}

.btn-outline {
  background: none !important;
  border-width: 2px;

  &:hover {
    background-color: hsla(0, 0%, 100%, 0.15) !important;
  }

  &.btn-outline-primary {
    border-color: $primary;
  }
}

.btn-transparent-white {
  color: #fff;
  background-color: hsla(0, 0%, 100%, 0.15);
  border-color: transparent;

  &:hover {
    @extend .btn-light;
    .dropdown-toggle {
      @extend .btn-light;
    }
  }

  .dropdown-toggle {
    color: $light;
  }
}

.btn-hover-primary {
  &:hover {
    color: $primary !important;
  }
}
.btn-hover-black {
  &:hover {
    color: $black !important;
  }
}
.btn-hover-bg-transparent {
  &:hover {
    background: transparent !important;
  }
}

.btn-geral {
  padding: 0.7em 0.5em;
  border-radius: 0.5em;
  color: #fff;
  font-size: 1.2em;
}
// buttons para parceria
.btn-andamento {
  background-color: #74c861;
}
.btn-encerrado {
  color: #9a9a9a;
}
.btn-suspenso {
  background-color: #fb724f;
}
.btn-analise {
  background-color: #e5c97b;
}

.bnt-border-termo-colaboracao {
  border-left: 10px solid orange;
}
